import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("ShippingAddress")
export default class ShippingAddress {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("address", String, true)
    address?: string = undefined
    @JsonProperty("postalCode", String, true)
    postalCode?: string = undefined
    @JsonProperty("population", String, true)
    population?: string = undefined
    @JsonProperty("province", String, true)
    province?: string = undefined
    @JsonProperty("phone", String, true)
    phone?: string = undefined
    @JsonProperty("email", String, true)
    email?: string = undefined
    @JsonProperty("fax", String, true)
    fax?: string = undefined
    @JsonProperty("observations", String, true)
    observations?: string = undefined

}
